<template>
    <div
        tabindex="0"
        class="container nes-container with-title"
        v-on:keyup.enter="getNewQuestion"
    >
        <span class="title">What type of {{ animal.type }} is this?</span>
        <div class="card-body">
            <img
                :key="animal.image"
                :src="animal.image"
                alt="Animal"
                style="image-rendering: pixelated;max-width: 100%; max-height: 400px;"
            />
        </div>
        <div style="margin-top: 20px;">
            <button
                v-for="(option, index) in options" :key="option"
                type="button"
                :disabled="evaluated"
                :class="{'nes-btn': true, 'is-success': wasCorrect(option), 'is-error': wasWrong(option), 'is-disabled': evaluated}"
                @click="makeSelection(option.readable_name)"
            >({{ index + 1 }}) {{ option.readable_name }}</button>
        </div>
        <div class="nes-container is-rounded" v-if="evaluated" style="margin-top: 20px;">
            <div class="result-container">
                <i v-if="correct" class="result-icon nes-icon trophy"></i>
                <i v-else class="result-icon nes-icon close"></i>
                <span class="result" v-if="correct">Correct!</span>
                <span class="result" v-else>Wrong, it is {{getArticle(animal.readable_name)}} {{ animal.readable_name }}!</span>
            </div>
            <br/>
            <button
                @click="getNewQuestion"
                type="button"
                class="nes-btn is-primary"
                style="margin-top: 10px;"
            >
               Play again
            </button>
        </div>
    </div>
</template>

<script>
var _ = require('lodash');
const breeds = {
    'cat': [
        "Abyssinian",
        "Bengal",
        "Birman",
        "Bombay",
        "British_Shorthair",
        "Egyptian_Mau",
        "Maine_Coon",
        "Persian",
        "Ragdoll",
        "Russian_Blue",
        "Siamese",
        "Sphynx"
    ],
    dog: [
        "american_bulldog",
        "american_pit_bull_terrier",
        "basset_hound",
        "beagle",
        "boxer",
        "chihuahua",
        "english_cocker_spaniel",
        "english_setter",
        "german_shorthaired",
        "great_pyrenees",
        "havanese",
        "japanese_chin",
        "keeshond",
        "leonberger",
        "miniature_pinscher",
        "newfoundland",
        "pomeranian",
        "pug",
        "saint_bernard",
        "samoyed",
        "scottish_terrier",
        "shiba_inu",
        "staffordshire_bull_terrier",
        "wheaten_terrier",
        "yorkshire_terrier"
    ]
};
let randomIndex = function(max_num) {
    return Math.floor(Math.random() * max_num);
};

import Store from "../store";
export default {
    data() {
        return {
            animal: "",
            picked: null,
            options: [],
            evaluated: null,
            correct: false,
            store: null
        };
    },
    created() {
        this.store = new Store();
        window.addEventListener("keyup", (event) => {
            switch (event.key) {
                case "Enter":
                    this.getNewQuestion();
                    break;
                case "1":
                case "2":
                case "3":
                case "4":
                    this.makeSelection(this.options[Number(event.key) - 1].readable_name);
                    break;
            }
        });
    },
    beforeUnmount() {
        window.removeEventListener("keyup");
    },
    mounted() {
        this.getNewQuestion();
    },
    watch: {
      picked: function (newChoice) {
        this.evaluate(newChoice);
      },
    },
    methods: {
        makeSelection(choice) {
            console.log("picked " + choice)
            if (!this.evaluated) {
                this.picked = choice;
            }
        },
        getNewQuestion() {
            if (this.evaluated==false) {
                return;
            }
            console.log("getNewQuestion");
            let dog_or_cat = ['dog', 'cat'][randomIndex(2)];
            let selected_breed = breeds[dog_or_cat][randomIndex(breeds[dog_or_cat].length)];
            console.log(selected_breed);
            let selected_image = `./images/${selected_breed}_${randomIndex(20)+1}.jpg`;
            this.animal = {
                type: dog_or_cat,
                image: selected_image,
                name: selected_breed,
                readable_name: _.startCase(selected_breed.replace('_', ' ')),
            };
            let shuffled_all_options = breeds[dog_or_cat]
                .map((value) => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value);
            let options = new Set();
            options.add(selected_breed);
            for (let i = 0; options.size < 4; i++) {
                options.add(shuffled_all_options[i]);
            }
            this.options = Array.from(options)
                .map((value) => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => {
                    let readable_name = _.startCase(value.replace('_', ' '));
                    return {
                        readable_name,
                        is_correct: readable_name == this.animal.readable_name,
                    };
                });
            this.evaluated = false;
        },
        evaluate(option) {
            if (option == this.animal.readable_name) {
                this.correct = true;
            } else {
                this.correct = false;
            }
            if (!this.evaluated) {
                this.updateScore(this.correct);
            }
            this.evaluated = true;
        },
        updateScore(correct) {
            if (correct) {
                this.store.increaseScore();
            } else {
                this.store.decreaseScore();
            }
        },
        wasWrong(option) {
            return this.picked == option.readable_name && this.evaluated && option.readable_name != this.animal.readable_name;
        },
        wasCorrect(option) {
            return this.evaluated && option.readable_name == this.animal.readable_name;
        },
        getArticle(name) {
            let vowelRegex = '^[aieouAIEOU].*';
            let matched = name.match(vowelRegex);
            return (matched ? 'an' : 'a');
        }
    },
};
</script>
