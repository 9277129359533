class Store {
    constructor() {
        const instance = this.constructor.instance;
        if (instance) {
            return instance;
        }
        this.constructor.instance = this;
        let storageScore = localStorage.getItem('score');
        if (storageScore === null) {
          this.score = 0;
          localStorage.setItem('score', this.score);
        } else {
          this.score = Number(storageScore);
        }
    }
    getScore() {
        return this.score;
    }
    setScore(score) {
        this.score = score;
        localStorage.setItem('score', this.score);
    }
    increaseScore() {
      this.setScore(this.score+1);
    }
    decreaseScore() {
      this.setScore(this.score-1);
    }
}

export default Store;
